import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: left;
    align-items: right;
    flex-direction: column;
    height: 70%;
    width: 30%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 5px #ccc;
    font-family: sans-serif;
    font-size: 14px;
    color: #333;
    margin-top: 10%;
    margin-bottom: 10%;
`;

export const Field = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    label {
        margin-bottom: 5px;
    }
    input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 3px;
        font-size: 14px;
    }
`;

export const Button = styled.button`
    background-color: #0069ed;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    align-self: center;
    &:hover {
        background-color: #0050c8;
    }
`;

export const Text = styled.p`
    text-align: center;
    margin-top: 10px;
    span {
        font-weight: 800;
        cursor: pointer;
    }
`;
