import styled from "styled-components";

const size = {
    mobileS: "0px",
    mobileM: "375px",
    mobileL: "425px",
    tablet: "768px",
    tabletL: "992px",
    laptop: "1024px",
    laptopL: "1440px",
    desktop: "2560px",
};

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    tabletL: `(min-width: ${size.tabletL})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
};

export const Colors = {
    ogBg: "linear-gradient(to left, #2A5470, #38476e)",
    sea: "rgba(104, 127, 140, 0.99)",
    primary: '#162238',
    dark: '#12121c',
    field: '#91b2c4',
    empty: 'rgba(104, 127, 140, 0.01)',
    homebutton: 'rgb(83, 117, 221)',
    white: '#ffffff',
};

export const Container = styled.div`
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
`;

export const Mega = styled.div`
    background-image: ${Colors.altBg};
    padding: 3%;
    box-sizing: border-box;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 200px;
`;

export const Field = styled.div`
    width: 100%;
    display: flex;
    white-wrap: normal;
    display: inline-block;
    & label, input, textarea {
        width:100%;
        position: relative;
        display: block;
        margin: auto;
        font-size: 14px;
        ::-webkit-input-placeholder {
            color: ${Colors.primary};
        }
    }
    & input, textarea {
        background-color: ${Colors.field};
        color: ${Colors.primary};
    }
    & label {
        margin-bottom: 8px;
    }
    & input {
        width: 400px;
        padding: 8px;
        margin: 10px 0 30px 0;
        float: left;
        border: none;
    }
    & textarea {
        height: 100px;
        padding: 7px;
    }
`;

export const Button = styled.button`
    background-color: ${Colors.primary};
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
        background-color: ${Colors.dark};
    }
`;

export const HorizontalScroller = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    height: 80%;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const HorizontalItem = styled.div`
    display: inline-block;
    width: 100%;
    margin: 0 10px;
    & img {
        width: 100%;
        height: auto; 
        max-height: calc(100% - 20px); 
        object-fit: cover;
        object-position: center;
        border-radius: 5px;
    }
    & p {
        font-size: 18px;
        color: ${Colors.dark};
        margin-top: 5px;
    }
`;

export const HorizontalMiniItem = styled.div`
    display: inline-block;
    width: 70%;
    height: 100%;
    margin: 0 10px;
    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 5px;
    }
`;
