import React from 'react';
import { Container, Content, Button } from './styles';

function PopUp(props) {
    return (
        <Container>
            <Content>
                {props.children}
                <Button onClick={props.onClose}>[close]</Button>
            </Content>
        </Container>
    )
}

export default PopUp;