import React from 'react';
import { Container, Img, MiniContainer, Button } from './styles';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.linkInterview = this.linkInterview.bind(this);
    };
    linkInterview() {
        window.open('/interviewer', '_self');
    }
    render() {
        return (
            <Container>
                <Img src={require('./logo.jpeg')} alt="logo" />
                <MiniContainer>
                    Entrevista creates digital interview coaches <br></br> 
                    to help you get your dream job.
                </MiniContainer>
                <Button onClick={this.linkInterview} >Start today!</Button>
            </Container>
        );
    };
};

export default Home;