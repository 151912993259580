import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, NavItems, NavItem, Container, Brand } from './styles';

const NavBar = ({ onSettingsClick }) => {
    return (
        <Navbar>
            <Container>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <Brand>Entrevista</Brand>
                </Link>
                <NavItems>
                    <Link to="/about" style={{ textDecoration: 'none' }}>
                        <NavItem>About Us</NavItem>
                    </Link>
                    <Link to="/interviewer" style={{ textDecoration: 'none' }}>
                        <NavItem>Casing Prep</NavItem>
                    </Link>
                    <Link onClick={onSettingsClick} style={{ textDecoration: 'none' }}>
                        <NavItem>⚙️</NavItem>
                    </Link>
                </NavItems>
            </Container>
        </Navbar>
    );
};

export default NavBar;