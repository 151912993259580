import styled from 'styled-components';
import { Colors } from '../../styles/index';

export const Container = styled.div`
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    margin-top: 100px;
    line-height: 25px;
`;

export const Img = styled.div`
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    margin-top: 50px;
    line-height: 25px;
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px;
    border-radius: 10px;
`;

export const MiniContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 35px;
    font-weight: 400;
    line-height: 55px;
    text-align: center;
    align-items: center;
`;

export const Button = styled.button`
    background-color: ${Colors.homebutton};
    color: white;
    padding: 20px 30px;
    font-size: 25px;
    cursor: pointer;
    border-radius: 50px;
    border: none;
    display: block;
    margin: 80px auto;
    :hover {
        background-color: ${Colors.primary};
    }
`;