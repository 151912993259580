import React from 'react';
import { Img, ButtonContainer, Button} from './styles';
import { HorizontalScroller, HorizontalItem, HorizontalMiniItem } from '../../styles';
import armyHotelImg from './cases/armyhotel.png'
import quahogSchoolsImg from './cases/quahog.png'
import wellingtonImg from './cases/wellington.png'

function Gallery({ case_image, updateCaseID, setCaseImage, startCaseClick }) {
    const imageMap = {
        armyhotel: {
            img: armyHotelImg,
            name: "Army Hotel",
            src: "armyhotel.txt"
        },
        quahog: {
            img: quahogSchoolsImg,
            name: "Quahog Public Schools",
            src: "quahog.txt"
        },
        wellington: {
            img: wellingtonImg,
            name: "Wellington",
            src: "wellington.txt"
        }
    }

    const handleImageClick = (case_id) => {
        setCaseImage(imageMap[case_id].img);
        updateCaseID(case_id+'.txt');
    };
    
    return (
        <>
            {!case_image && (
                <>
                    <h2>Choose a case:</h2><br></br>
                    <HorizontalScroller>
                        {Object.entries(imageMap).map(
                            ([key, value]) => (
                                <HorizontalItem key={key}>
                                    <Img
                                        src={value.img}
                                        alt={value.name}
                                        onClick={() => 
                                            handleImageClick(key)
                                        }
                                    />
                                    <p>{value.name}</p>
                                </HorizontalItem>
                            ))
                        }
                    </HorizontalScroller>
                </>
            )}
            {case_image && (
                <HorizontalScroller>
                    <HorizontalMiniItem>
                        <Img src={case_image} alt=''></Img>
                    </HorizontalMiniItem>
                    <HorizontalItem>
                        <ButtonContainer>
                            <Button onClick={startCaseClick}>Start Casing</Button>
                        </ButtonContainer>
                    </HorizontalItem>
                </HorizontalScroller>
            )}
        </>
    );
}

export default Gallery;