import styled from "styled-components";
import { Colors } from "../../styles/index";

export const Session = styled.div`
    overflow: scroll;
    margin-bottom: 100px;
`;

export const Img = styled.img`
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    display: block;
    padding: 20px;
`;

export const Cols = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const It = styled.div`
    width: 48%;
`;

export const Container = styled.div`
    padding-top: 100px;
    max-width: 1024px;
    width: 100%;
    margin: auto;
    color: ${Colors.primary};
    display: block;
    text-align: left;
`;

export const Message = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    // transform: translateX(0%);
    padding: 15px 10%;
    background-color: #2ecc71;
    color: #fff;
    font-size: 16px;
`

export const ErrorMessage = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    // transform: translateX(0%);
    padding: 15px 10%;
    background-color: #801b32;
    color: #fff;
    font-size: 16px;
`
