import React from "react";
import Recorder from "../../components/Recorder/"
import SignUp from "../../components/SignUp/";
import SignIn from "../../components/SignIn/";
import Gallery from "../../components/Gallery/";
import { 
  Session,
  Img,
  Container,
  Message,
  ErrorMessage,
  Cols,
  It 
} from "./styles.js";

const server_link = process.env.REACT_APP_API_URL;
console.log("Server link: ", server_link);

class Case extends React.Component {
  constructor(props) {
    super(props);
    this.user_data = {
      _id: "",
      password: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
    }
    this.session = {
      session_id: "", 
      case_id: "",
      response: "",
    }
    this.state = {
      error: false,
      message: 'Your feedback will come up here as you submit videos', 
      is_loading: false,
      show_sign_up: false,
      session_on: false,
      signed_in: false,
      video: null,
      user_data: this.user_data,
      session: this.session,
      case_image: null,
    }

    // helpers
    this.callError = this.callError.bind(this);
    this.set_case_image = this.set_case_image.bind(this);
    this.updateCaseID = this.updateCaseID.bind(this);
    this.textToSpeech = this.textToSpeech.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleAccount = this.toggleAccount.bind(this);
    this.handleAccountState = this.handleAccountState.bind(this);

    // servers
    this.sign_up = this.sign_up.bind(this);
    this.sign_in = this.sign_in.bind(this);
    this.start_case = this.start_case.bind(this);
    this.submit_video = this.submit_video.bind(this);
  };

  callError(error) {
    console.log(error)
    this.setState({
      error: true,
      message: "An error occurred. Please try again",
      is_loading: false
    });
  }

  // account functions

  handleInputChange = (key, value) => {
    this.setState(prevState => ({
      user_data: {
        ...prevState.user_data,
        [key]: value
      }
    }));
  };

  toggleAccount = () => {
    this.setState(prevState => ({
        show_sign_up: !prevState.show_sign_up
    }));
  };

  handleAccountState(data) {
    if (data.statusCode === 200) {
      this.setState({
        user_data: data.user_data,
        signed_in: true,
      });
    } else {
      this.setState({
        error: true,
      });
    };
    this.setState({
      message: data.message,
      is_loading: false,
    });
  }

  sign_up(event) {
    event.preventDefault();

    this.setState({
      error: false,
      message: 'Signing up...',
      is_loading: true,
    });

    fetch(server_link +'/user/sign_up', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(this.state.user_data)
    }).then((response) => {
      if (!response.ok) {
        throw Error(response.status + ' ' + response.statusText);
      }
      return response.json();
    }).then((data) => {
      this.handleAccountState(data);
    }).catch((error) => {
      this.callError(error);
    });
  }

  sign_in (event) {
    event.preventDefault();

    const userData = {
      '_id': this.state.user_data._id,
      'password': this.state.user_data.password,
    };
    console.log(userData)

    this.setState({
      error: false,
      message: 'Signing in...',
      is_loading: true,
    });

    fetch(server_link +'/user/sign_in', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(userData)
    }).then((response) => {
      if (!response.ok) {
        throw Error(response.status + ' ' + response.statusText);
      }
      return response.json();
    }).then((data) => {
      this.handleAccountState(data);
    }).catch((error) => {
      this.callError(error);
    });
  }

  // session functions

  set_case_image = (source) => {
    this.setState({
      case_image: source,
    });
  };

  updateCaseID(caseID) {
    this.setState(prevState => ({
      session: {
        ...prevState.session,
        case_id: caseID
      }
    }));
  };

  textToSpeech(text) {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = 'en-US';
    utterance.pitch = 1;
    utterance.rate = .9;
    utterance.volume = 10;
    synth.speak(utterance);
    // utterance.onend = () => {  }; start recording here
  };

  start_case(event) {
    event.preventDefault();

    const submitData = {
      '_id': this.state.user_data._id,
      'case_id': this.state.session.case_id,
    };
    console.log(submitData);

    this.setState({
      error: false,
      message: 'Starting session...',
      is_loading: true,
    });

    fetch(server_link +'/session/start_case', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(submitData)
    }).then((response) => {
      if (!response.ok) {
        throw Error(response.status + ' ' + response.statusText);
      }
      return response.json();
    }).then((data) => {
      console.log(data);
      console.log(data.statusCode)
      console.log(data.statusCode === 200)
      this.setState({
        message: data.message,
        is_loading: false,
        session: {
          session_id: data.session_id,
          response: data.response,
        },
      });
      if (data.statusCode === 200) {
        this.setState({ session_on: true, });
        this.textToSpeech(data.response);
      } else {
        this.setState({ error: true, });
      }
    })
    .catch((error) => {
      this.callError(error);
    });
  }

  submit_video = (video) => {
    this.setState({
      error: false,
      video: video,
      is_loading: true, 
      message: 'Processing your response...' 
    });

    const vidData = new FormData();
    vidData.append('_id', this.state.user_data._id);
    vidData.append('session_id', this.state.session.session_id);
    vidData.append('video', video);

    fetch(server_link + "/session/submit_video", { 
      method: 'POST',
      body: vidData
    }).then((response) => {
      if (!response.ok) {
        throw Error(response.status + ' ' + response.statusText);
      }
      return response.json();
    }).then((data) => {
      this.setState({
        video: null,
        is_loading: false,
        message: data.message,
      });
      if (data.statusCode === 200) {
        this.textToSpeech(data.response);        
      } else {
        this.setState({
          error: true,
        });
      };
    })
    .catch((error) => {
      this.callError(error);
    });
  };


  render() {
    const { 
      case_image, 
      error,
      message,
      is_loading,
      session_on,
      signed_in,
      user_data,
      show_sign_up
    } = this.state;
    return ( 
      <>
        <Container>
          { signed_in ? 
            ( 
              // Signed in //
              <> 
              { session_on ? (

                // Session started //
                <Session>

                  <h2>Welcome, {user_data.first_name}</h2>

                  <Cols>
                    <It>
                      {case_image && <Img src={case_image} alt='' />}
                    </It>
                    <It> 
                      {is_loading ? (
                        <h4>{message}</h4>
                      ) : (
                        <Recorder onVideoRecorded = {(video) => this.submit_video(video)} />
                      )}

                    </It>
                  </Cols>

                </Session>
                // Session started //

              ) : (

                // Session not started //
                <Session> 
                  { is_loading ? (
                    // Loading session
                    <h3>Loading session...</h3>
                  ) : ( 
                    // Not loading session 
                    <>
                      <h3>
                        Welcome {user_data.first_name}!
                        Pick a case to start your session.
                      </h3>
                      <Gallery
                        case_image={case_image}
                        updateCaseID={this.updateCaseID}
                        setCaseImage={this.set_case_image} 
                        startCaseClick={this.start_case}
                      />
                    </>
                  )}
                </Session>
                // Session not started //

              )}

              </> 
              // Signed in //

            ) : ( 

            // Not Signed In // 
            <>
              {show_sign_up ? 
                <SignUp 
                    user_data={user_data}
                    onInputChange={this.handleInputChange}
                    onSignUp={this.sign_up}
                    toggleForm={this.toggleAccount}
                /> : 
                <SignIn 
                    user_data={user_data}
                    onInputChange={this.handleInputChange}
                    onLogin={this.sign_in}
                    toggleForm={this.toggleAccount}
                />
              }
            </> 
            // Not signed in//

          )}

        </Container>

        {error ? (<ErrorMessage>{message && <h4>{this.state.message}</h4>}</ErrorMessage>) : 
        (<Message>{message && <h4>{this.state.message}</h4>}</Message>)}

      </>
    )
  }
}

export default Case;