import React from 'react';
import { Container, Field, Button, Text } from '../SignUp/styles';

function SignIn({ user_data, onInputChange, onLogin, toggleForm}) {
  return (
    <Container>
        <h3>Welcome to Entrevista 🎓</h3>
        <Field>
            <label htmlFor="id-input">Username:</label>
            <input 
                type="text"
                id="id-input"
                placeholder="Your username here" 
                value={user_data._id} 
                onChange={(event) => onInputChange("_id", event.target.value)} 
            />
        </Field>
        <Field>
            <label htmlFor="password-input">Password:</label>
            <input
                type="password"
                id="password-input"
                placeholder="Your password here"
                value={user_data.password}
                onChange={(event) => onInputChange("password", event.target.value)} 
            />
        </Field>
        <Button onClick={onLogin}>Login</Button>
      <Text><span onClick={toggleForm}> Don't have an account? Create one! 🎉</span></Text>
    </Container>
  );
}

export default SignIn;
