import React from 'react';
import SignIn from '../../components/SignIn';
import SignUp from '../../components/SignUp';

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            signedIn: true,
            showSignUp: false,
            user_data: {}
        }
        this.toggleSignIn = this.toggleSignIn.bind(this);
    };

    toggleSignIn() {
        this.setState({showSignUp: !this.state.showSignUp});
    };

    render () {
        const { showSignUp, signedIn, user_data } = this.state;
        return (
            // info about the user, ability to change it, and also to delete the account, or log out
            signedIn ?
            <>
                <h1>Settings</h1>
                <h2>Account</h2>
                <h2>Change Password</h2>
                <h2>Change Email</h2>
                <h2>Change Phone Number</h2>
                <h2>Delete Account</h2>
                <h2>Log Out</h2>                
            </> :
            <> { showSignUp ? 
                <SignUp 
                    user_data={user_data}
                    onInputChange={this.handleInputChange}
                    onSignUp={this.sign_up}
                    toggleForm={this.toggleSignUp}
                /> : 
                <SignIn 
                    user_data={user_data}
                    onInputChange={this.handleInputChange}
                    onLogin={this.sign_in}
                    toggleForm={this.toggleSignUp}
                />
            } </>
        )
    }
};

export default Settings;
