import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Mega, Container } from './styles';
import Home from './pages/Home/';
import Interviewer from './pages/Interviewer/';
import About from './pages/About/';
import NavBar from './components/NavBar';
import PopUp from './components/PopUp';
import Settings from './pages/Settings';

function App() {
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const toggleSettings = () => {
    setIsSettingsVisible(!isSettingsVisible);
  }

  return (
    <Mega>
      <Container>
        <Router>
          <NavBar onSettingsClick = {toggleSettings} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/interviewer" element={<Interviewer />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </Router>
        {isSettingsVisible && <PopUp onClose={() => setIsSettingsVisible(false)}> <Settings /> </PopUp>}
      </Container>
    </Mega>
  );
}

export default App;