import styled from "styled-components";
import { Colors } from "../../styles/index";

export const Img = styled.img`
    width: 30%;
    height: 30%;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;  // Horizontally centers the content
    align-items: center;      // Vertically centers the content
    height: 100%;             // Takes up the full height of the parent (HorizontalItem)
`;

export const Button = styled.button`
    background-color: ${Colors.primary};
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:hover {
        background-color: ${Colors.dark};
    }
`;