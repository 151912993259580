import styled from "styled-components";
import { Colors } from "../../styles/index";

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    background: ${Colors.empty};
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    height: 40%;
`;

export const Button = styled.button`
    background-color: #0069ed;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    align-self: center;
    &:hover {
        background-color: #0050c8;
    }
`;
