import React, { useRef, useState }  from 'react';
import { Button, Container, Video } from './styles';

const Recorder = ({ onVideoRecorded }) => {
    const [recording, setRecording] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const videoRef = useRef(null);

    const [videoURL, setVideoURL] = useState(null);

    const startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        const newMediaRecorder = new MediaRecorder(stream);
        videoRef.current.srcObject = stream;

        newMediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                setRecordedChunks((prev) => prev.concat(event.data));
            }
        };
        newMediaRecorder.start(100);
        setMediaRecorder(newMediaRecorder);
        setRecording(true);
    };

    const stopRecording = () => {
        mediaRecorder.onstop = () => {
            videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
            setRecording(false);

            const blob = new Blob(recordedChunks, { type: "video/webm" });
            onVideoRecorded(blob);

            const url = URL.createObjectURL(blob);
            setVideoURL(url);

            setRecordedChunks([]);
            setMediaRecorder(null);
        };
        mediaRecorder.stop();
    };

    return (
        <Container>
            <Video ref={videoRef} autoPlay muted></Video>
            { recording ? (
                <Button onClick={stopRecording}>Submit</Button>
            ) : (
                <>
                    <Button onClick={startRecording}>Start Recording</Button><br></br>
                    {videoURL && <a href={videoURL} download="recorded-video.webm">Download Previous Video</a>}
                </>
            )}
        </Container>
    )
};

export default Recorder;