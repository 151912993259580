import styled from "styled-components";
import { Colors } from "../../styles/index";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%; 
`;

export const Video = styled.video`
    width: 500px;
    height: auto;
    margin: 30px auto;
    display: block;
    object-fit: cover;
    object-position: center;
`;

export const Button = styled.button`
    background-color: ${Colors.primary};
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    align-self: center;
    &:hover {
        background-color: ${Colors.dark};
    }
`;