import React from 'react';
import { Subtitle, Container, Button, Video } from './styles';

class About extends React.Component {
    constructor(props) {
        super(props);
        this.linkInterview = this.linkInterview.bind(this);
    };

    linkInterview(event) {
        event.preventDefault();
        window.open('/interviewer', '_self');
    }

    render() {
        return (
            <Container>
                <h1>About</h1>
                <p>
                    Entrevista was founded in 2023 by a group of students at Harvard University.
                    Our goal is to help students prepare for interviews by providing a platform
                    for them to practice with AI-powered coaches. We hope that this will help
                    students feel more confident and prepared for their interviews.
                </p>
                <Subtitle>
                    Check out our demo!
                </Subtitle>
                <Video
                    title="Our demo"
                    width="560"
                    height="315"
                    src="https://youtube.com/embed/H-lqUid49cc"
                    frameborder="0"
                    allow="
                        accelerometer; 
                        autoplay;
                        picture-in-picture
                    "
                    allowfullscreen
                />
                <Subtitle>
                    Our founders video
                </Subtitle>
                <Video
                    title="Our founders video"
                    src="https://youtube.com/embed/iBi76gQJ-AM"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                />
                <br></br>
                <Button onClick={this.linkInterview}>
                    Start Preparing for Interviews!
                </Button>
            </Container>
        );
    };
};

export default About;