import React from 'react';
import { Container, Field, Button, Text } from './styles';

const SignUp = ({user_data, onInputChange, onSignUp, toggleForm}) => {
    return (
        <Container>
            <h1>Create an account 🎉</h1>
            <Field>
                <label htmlFor="id-input">Username:</label>
                <input 
                    type="text"
                    id="id-input"
                    placeholder="Your username here"
                    value={user_data._id}
                    onChange={(event) => onInputChange("_id", event.target.value)} >
                </input>
            </Field>
            <Field>
                <label htmlFor="password-input">Password:</label>
                <input 
                    type="password"
                    id="password-input"
                    placeholder="Your password here"
                    value={user_data.password}
                    onChange={(event) => onInputChange("password", event.target.value)} >
                </input>
            </Field>
            <Field>
                <label htmlFor="first-name-input">First Name:</label>
                <input 
                    type="text" id="first-name-input" 
                    placeholder="Your first name here"
                    value={user_data.first_name}
                    onChange={(event) => onInputChange("first_name", event.target.value)} >
                </input>
            </Field>
            <Field>
                <label htmlFor="last-name-input">Last Name:</label>
                <input 
                    type="text" id="last-name-input"
                    placeholder="Your last name here"
                    value={user_data.last_name}
                    onChange={(event) => onInputChange("last_name", event.target.value)} >
                </input>
            </Field>
            <Field>
                <label htmlFor="email-input">Email:</label>
                <input
                    type="email"
                    id="email-input"
                    placeholder="Your email here"
                    value={user_data.email}
                    onChange={(event) => onInputChange("email", event.target.value)} >
                </input>
            </Field>
            <Field>
                <label htmlFor="phone-number-input">Phone Number:</label>
                <input 
                    type="phone"
                    id="phone-number-input"
                    placeholder="Your phone number here"
                    value={user_data.phone_number}
                    onChange={(event) => onInputChange("phone_number", event.target.value)} >
                </input>
            </Field>
            <Button onClick={onSignUp}>Sign Up</Button>
            <Text><span onClick={toggleForm}> Already have an account? Log in! 🎉</span></Text>
        </Container>
    )
}

export default SignUp;
