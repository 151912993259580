import styled from "styled-components";
import { Colors } from "../../styles/index";

export const Container = styled.div`
    padding-top: 100px;
    max-width: 1024px;
    width: 100%;
    margin: auto;
    color: ${Colors.primary};
    display: block;
    text-align: left;
`;

export const Subtitle = styled.h2`
    color: ${Colors.primary};
    font-size: 1.5em;
    margin: 50px 0px 10px 0px;
    text-align: center;
`;

export const Video = styled.iframe`
    margin: 20px auto;
    display: block;
    width: 80%;
    height: 500px;
`;

export const Button = styled.button`
    margin: 20px auto;
    display: block;
    background-color: ${Colors.primary};
    color: ${Colors.white};
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1.2em;
    cursor: pointer;
    &:hover {
        background-color: ${Colors.primaryDark};
    }
`;
